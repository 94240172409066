import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, ButtonBase, Divider, Drawer, Link, Typography } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ColorsConst from "../../utils/ColorsConst";
import history from "../../router/history";
import { RootState } from "../../redux/store";

import "./sideNav.css";
import { setToken } from "../../redux/reducers/appSlice";
import { ROUTES } from "../../api/config";
import { get } from "../../api";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";
import { PRIVACY_POLICY, TERMS_OF_USE } from "../../utils/Const";
import LogoutModal from "./LogoutModal";
import { useState } from "react";
import packageJson from '../../../package.json';


function generateRegex(patterns) {
  const regexPattern = `^/(${patterns.join("|")})$`;
  return new RegExp(regexPattern);
}


const FIRST_ITEMS = [
  {
    pathnames: ["all-meetings", "meeting-details/\\d+"],
    icon: TodayIcon,
    name: "My Meetings",
    count: 0,
  },
  {
    pathnames: ["shared-meetings" , "shared/meeting-details/\\d+"],
    icon: GroupIcon,
    name: "Shared Meetings",
    count: 0,
  },
  // {
  //   pathnames: ["past-meetings"],
  //   icon: TodayIcon,
  //   name: "Past meetings",
  //   count: 0,
  // },
  // {
  //   pathnames: [
  //     "all-clients",
  //     "client/\\d+",
  //     "client/\\d+/meeting-details/\\d+",
  //   ],
  //   icon: PersonOutlineOutlinedIcon,
  //   name: "Clients",
  //   count: 0,
  // },
];

const SECOND_ITEMS = [
  {
    pathnames: ["customize-notes"],
    icon: KeyboardAltOutlinedIcon,
    name: "Customize",
    count: 0,
  },
  {
    pathnames: ["settings"],
    icon: SettingsOutlinedIcon,
    name: "Settings",
    count: 0,
  },
];



const SideNavItem = ({ icon: Icon, ...props }) => {
  const { isExtension } = useDeviceStatus();
  const location = useLocation();
  const regex = generateRegex(props.pathnames);
  const selected = regex.test(location.pathname);
  const textColor = selected
    ? ColorsConst.SURFACE_CONTAINER_LOW
    : ColorsConst.ON_SURFACE_VARIANT;

  const _onClick = () => {
    history.push(`/${props.pathnames[0]}`);
    props.handleClose();
  };

  if (isExtension) {
    const extensionTextColor = selected
      ? ColorsConst.SURFACE_CONTAINER_LOW
      : ColorsConst.ON_SURFACE_VARIANT;
    return (
      <ButtonBase sx={{ display: "block", mb: "12px" }} onClick={_onClick}>
        <Box
          sx={{
            bgcolor: selected ? ColorsConst.DARK_PRIMARY : "transparent",
            borderRadius: 8,
            px: 2,
            display: "flex",
            alignItems: "center",
            py: "4px",
            mb: "4px",
          }}
        >
          <Icon sx={{ color: textColor, height: 20 }} />
        </Box>
        <Typography
          variant="caption"
          sx={{
            color: extensionTextColor,
            textAlign: "center",
          }}
        >
          {props.name}
        </Typography>
        {/* {!!props.count && (
          <Typography sx={{ color: textColor }}>{props.count}</Typography>
        )} */}
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        my: 2,
        bgcolor: selected ? ColorsConst.DARK_PRIMARY : "transparent",
        px: 2,
        py: 1.2,
        borderRadius: 8,
        width: "100%",
      }}
      onClick={_onClick}
    >
      <Icon sx={{ color: textColor }} />
      <Typography
        variant={"subtitle2"}
        sx={{
          color: textColor,
          mx: 2,
          flex: 1,
          textAlign: "left",
        }}
      >
        {props.name}
      </Typography>
      {!!props.count && (
        <Typography sx={{ color: textColor }}>{props.count}</Typography>
      )}
    </ButtonBase>
  );
};

function SideNav(props) {
  const { isExtension, isMobile } = useDeviceStatus();
  const userDetails = useSelector((state: RootState) => state.app.userDetails);
  const dispatch = useDispatch();
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const firstName = userDetails?.name?.split(" ")?.[0];

  const handleClose = () => {
    props.setIsDrawerOpen(false);
  };

  const onLogout = async () => {
    dispatch(setToken(null));
    Mixpanel.track(EventNames.USER_LOGOUT, {
      'Log Out Method': 'Manual Logout'
    })
    Mixpanel.reset();
    try {
      const res = await get(ROUTES.LOGOUT);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer
      variant={!isMobile || isExtension ? "permanent" : "temporary"}
      // For mobile mode, add the following properties:
      open={props.isDrawerOpen} // isOpen is a state variable that controls the drawer's visibility
      onClose={handleClose} // handleClose is a function to close the drawer
      ModalProps={{ keepMounted: true }} // To improve performance on mobile
      // For desktop mode, remove the open and onClose properties
      className={isExtension ? "chrome-extension" : ""}
    >
      <Box
        sx={{
          backgroundColor: ColorsConst.DARK_SURFACE_CONTAINER,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          flex={1}
          sx={{
            marginTop: "70px",
            "@media (min-width: 600px)": {
              marginTop: "64px",
            },
          }}
        >
          {FIRST_ITEMS.map((item) => (
            <SideNavItem {...item} key={item.name} handleClose={handleClose} />
          ))}
          {/* {!isExtension && (
            <Divider
              sx={{ backgroundColor: ColorsConst.DARK_OUTLINE_VARIANT }}
            />
          )}
          {SECOND_ITEMS.map((item) => (
            <SideNavItem {...item} key={item.name} handleClose={handleClose} />
          ))} */}
        </Box>
        <Box>
          {!isExtension && (
            <Divider
              sx={{ backgroundColor: ColorsConst.DARK_OUTLINE_VARIANT }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: isExtension ? "column" : "row",
              pt: 2,
            }}
          >
            <AccountCircleIcon sx={{ color: ColorsConst.ON_SURFACE_VARIANT }} />
            <Typography
              variant={isExtension ? "caption" : "subtitle2"}
              sx={{
                color: ColorsConst.ON_SURFACE_VARIANT,
                ml: isExtension ? 0 : "12px",
                textAlign: "center",
                mt: "4px",
              }}
            >
              {firstName}
            </Typography>
          </Box>
          <ButtonBase
            sx={{
              width: "100%",
              justifyContent: isExtension ? "center" : "flex-start",
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => setIsLogoutModal(true)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: isExtension ? "column" : "row",
                pb: 2,
                pt: 2,
              }}
            >
              <LogoutIcon sx={{ color: ColorsConst.ON_SURFACE_VARIANT }} />
              <Typography
                variant={isExtension ? "caption" : "subtitle2"}
                sx={{
                  color: ColorsConst.ON_SURFACE_VARIANT,
                  ml: isExtension ? 0 : "12px",
                  textAlign: "center",
                  mt: "4px",
                }}
              >
                Logout
              </Typography>
            </Box>
          </ButtonBase>
          <Typography variant="body2" sx={{ color: '#7A7488', mb: 2, textAlign: isExtension ? 'center' : 'inherit' }}>Read our <Link sx={{ color: '#7A7488', cursor: "pointer", textDecorationColor: '#7A7488' }}
            href={TERMS_OF_USE}
            underline='always'
            target="_blank"
            rel="noopener"
          >
            Terms of Use
          </Link> and <Link sx={{ color: '#7A7488', cursor: "pointer", textDecorationColor: '#7A7488' }}
            href={PRIVACY_POLICY}
            underline='always'
            target="_blank"
            rel="noopener"
          >Privacy Policy.</Link>
          </Typography>
          <Typography variant='caption' sx={{ color: '#7A7488', fontSize: '10px', position: 'absolute', left: '30px', bottom: '8px', ml: isExtension ? '-6px' : -2 }}>v{packageJson.version}</Typography>
        </Box>
      </Box>
      <LogoutModal open={isLogoutModal} onSecondaryClick={() => setIsLogoutModal(false)} onPrimaryClick={onLogout} />
    </Drawer >
  );
}

export default SideNav;
