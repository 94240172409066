const ColorsConst = {
  DARK_SURFACE_CONTAINER: "#211E29",
  SECONDARY_FIXED_DIM: "#89D0F1",
  SECONDARY_FIXED: "#BEE9FF",
  ON_SURFACE_VARIANT: "#CBC3D9",
  DARK_SURFACE: "#15121C",
  ON_SURFACE: "#E7E0F0",
  ON_SECONDARY_CONTAINER: "#004256",
  DARK_SECONDARY_CONTAINER: "#92D9FB",
  SURFACE_CONTAINER_LOW: "#1D1A25",
  SURFACE_CONTAINER_LOWEST: "#EADDFF29",
  SURFACE_VARIANT: "#E8DFF6",
  DARK_PRIMARY: "#CFBDFF",
  DARK_SURFACE_CONTAINER_HIGHEST: "#37333F",
  DARK_TERTIARY: "#D8B9FF",
  DARK_ON_PRIMARY_CONTAINER: "#F3EAFF",
  ON_PRIMARY: "#3A0093",
  DARK_OUTLINE_VARIANT: "#494456",
  DARK_PRIMARY_CONTAINER: "#5D00E2",
  WHITE: "#FFF",
  BLACK: "#000",
  DARK_OUTLINE: "#958DA2",
  LIGHT_MEDIUM_CONTRAST_OUTLINE_VARIANT: "#7E788C",
  PALETTES_NEUTRAL_VARIANT_25: "#3C3B3C",
  DARK_SURFACE_CONTAINER_HIGH: "#2C2834",
  SCHEMES_SECONDARY_COLOR: "#A8E2FF",
  SCHEMES_ERROR: "#9E3E48",
  CALL_GREEN: "#388E3C",
  LIGHT_MEDIUM_CONTRAST_SECONDARY_CONTAINER: "#307D9B",
  LIGHT_PRIMARY_FIXED: "#E8DDFF",
  LIGHT_HIGH_CONTRAST_SECONDARY_FIXED: '#00495F',
  TERITARY: '#C396FF',
  ON_SURFACE_BUTTON: "#CAC4D01F",
  DARK_ON_SURFACE: "#CAC4D029",
  DARK_SURFACE_BRIGHT: "#3B3743",
  DARK_ERROR_CONTAINER: "#E4747D",
  DARK_ON_SURFACE_OPAC: "#E6E0E914",
  ERROR:"#FFB2B6"
};

export default ColorsConst;
