// toastManager.ts
import { EventEmitter } from "events";

export interface ToastMessage {
  message: string;
  severity: "success" | "info" | "warning" | "error";
}

class ToastEmitter extends EventEmitter {
  public show(message: ToastMessage): void {
    this.emit("show", message);
  }
}

export const toastEmitter = new ToastEmitter();
