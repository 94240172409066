// store.ts
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/appSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    // Add other reducers as needed
  },
  // Optional: Define middleware, dev tools, etc.
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
