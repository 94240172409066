export const SCREEN_LOAD = "Screen Load";
export const LOGIN_ATTEMPT = "Login Attempt";
export const OTP_ENTERED = "OTP Entered";
export const AUTHENTICATION_RESULT = "Authentication Result";
export const CARD_CLICKED = "Card Clicked";
export const BUTTON_CLICKED = "Button Clicked";
export const MEETING_CONTROLS_CLICKED = "Meeting Controls Clicked";
export const MODAL_OPENED = "Modal Opened";
export const MODAL_CLOSED = "Modal Closed";
export const MEETING_ALERT = "Meeting Alert";
export const MEETING_ALERT_CLEARED = "Meeting Alert Cleared";
export const NOTES_EDITED = "Notes Edited";
export const SNACKBAR_APPEARED = "Snackbar Appeared";
export const NOTES_REFRESHED = "Notes Refreshed";
export const SUMMARY_GENERATED = "Summary Generated";
export const SCREEN_NAVIGATION = "Screen Navigation";
export const USER_LOGOUT = "User Logout";
export const NOTIFICATION_SENT = "Notification Sent";
export const NOTIFICATION_INTERACTION = "Notification Interaction";
export const API_CALL = "API Call";
export const WEB_VITALS = "Web Vitals";
export const APP_RELOAD = "App Reload";
export const AUDIO_DEVICE_CHANGE = "Audio Device Changed";
export const SYSTEM_DEFAULT_AUDIO_DEVICE_CHANGE = "System Default Audio Device Changed";
export const NO_AUDIO_INPUT_DETECTED = "No Audio Input Detected";
export const NO_TRANSCRIPTION_DETECTED = "No Transcription Detected";
export const NO_NOTES_DETECTED = "No Notes Detected";
export const INITIAL_AUDIO_DEVICES_DETECTED = "Initial Audio Devices Detected";
export const INITIAL_AUDIO_DEVICES_ERROR = "Initial Audio Devices Error";
export const APP_LOAD = "App Load";
export const LIST_AUDIO_DEVICES_ON_CHANGE = "List Audio Devices On Change";
export const FILTER_APPLIED = "Filter Applied";
export const BANNER_SHOWN = "Banner Shown";
export const MEETING_FETCHED_FROM_CALENDAR = "Meeting Fetched from Calendar";
export const OUTLOOK_SYNC_ATTEMPT = "Outlook Sync Attempt";
export const SHARE_ACCESS_CHANGED = "Share Access Changed";
