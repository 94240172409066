import { Box } from "@mui/material";
import Header from "../components/header";
import SideNav from "../components/sideNav";
import { Suspense, useState } from "react";
import { useDeviceStatus } from "../hooks/useDeviceStatus";

const Layout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isExtension, isMobile } = useDeviceStatus();
  const ml = isExtension ? "80px" : isMobile ? 0 : "225px";

  return (
    <Box height="100%">
      <Header setIsDrawerOpen={setIsDrawerOpen} />
      <SideNav isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <Box
        height="100%"
        ml={ml}
        sx={{
          mt: "54px",
          "@media (min-width:600px)": {
            mt: "64px",
          },
        }}
      >
        <Suspense>{children}</Suspense>
      </Box>
    </Box>
  );
};

export default Layout;
